<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" :name_sublabel="name_sublabel" />				
		    </b-col>		    
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom" v-model="tabIndex_parent">
			            <b-tab title="Basic Info" class="overview-tab">	
			            	<validation-observer ref="form_rel" #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20 mt-1 pb-0" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6">
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">

							            		<template v-if="$route.name == 'itees-strategic-edit'">
								                    
								                    <template v-if="(userData && permission.edit)">

								                    	<b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelEdit" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
									                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEdit">
									                        Save
									                    </b-button>
									                </template>
									                <template v-else>
									                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
									                        Edit
									                    </b-button>
									                </template>

								                </template>
								                <template v-else>
								                	<template v-if="(userData && permission.edit)">
								                		<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEdit(detail.id)">
									                        Edit
									                    </b-button>
								                	</template>
								                	<template v-else>
								                		<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
									                        Edit
									                    </b-button>
								                	</template>
								                </template>

							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">             
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group id="" label="Strategic Plan Name" label-cols-lg="2" class="star-required">  
				                              	    <validation-provider
					                                  #default="{ errors }"
					                                  name="Strategic Plan Name"
					                                  vid="name"
					                                  rules="required"                      
					                                >         	                
					                                    <b-form-input
					                                    	v-model="detail.name"
					                                      	placeholder="Name"
					                                      	:formatter="length100"
					                                      	:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="FY Start Year" label-cols-lg="4" class="star-required">
													<div class="calendarIcon">
                              							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                           							</div>
					                            	<!-- <validation-provider
					                                  #default="{ errors }"
					                                  name="FY Start Year"
					                                  vid="start_year"
					                                  rules="required"                      
					                                >     -->
					                                    <datepicker  
					                                    	format="yyyy" 
					                                    	minimum-view="year"
					                                    	placeholder="Select Year"
					                                    	class="w-100-child fieldCalendar_input"
					                                    	v-model="detail.start_year"
															:disabled="isDisabled"
															:id="disableField"
															required
					                                    >              	
					                                    </datepicker>
					                                    <!-- <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider> -->
					                            </b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="FY End Year" label-cols-lg="4" class="star-required">
													<div class="calendarIcon">
                              							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                           							</div>
					                                <!-- <validation-provider
					                                  #default="{ errors }"
					                                  name="FY End Year"
					                                  vid="end_year"
					                                  rules="required"                      
					                                >     -->
						                                <datepicker  
					                                    	format="yyyy" 
					                                    	minimum-view="year"
					                                    	placeholder="Select Year"
					                                    	class="w-100-child fieldCalendar_input"
					                                    	v-model="detail.end_year"
															:disabled="isDisabled"
															:id="disableField"
															required
					                                    >              	
					                                    </datepicker>
					                                    <!-- <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider> -->
					                            </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Status"
					                                  vid="statusDetailText"
					                                  rules="required"                      
					                                >  
						                                <v-select
						                                    label="title"
						                                    :options="selectStatus"
						                                    v-model="statusDetailText"
						                                    placeholder="Select Status"
															:disabled="isDisabled"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 		                          	
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Strategic Plan Description" label-cols-lg="2" class="star-required">
					                            	<validation-provider
					                                  #default="{ errors }"
					                                  name="Strategic Plan Description"
					                                  vid="statusDetailText"
					                                  rules="required"                      
						                            >  
						                            	<b-form-textarea
						                            		v-model="detail.description"
						                                    placeholder="Strategic Plan Description"
						                                    rows="4"
						                                    :formatter="length1000"
															:disabled="isDisabled"
															style="padding-top: 12px !important"
						                                ></b-form-textarea>
						                                <small class="text-danger">{{ errors[0] }}</small>
						                            </validation-provider>
					                            </b-form-group>
				                          	</b-col>   
				                      	</b-row>
					                </div>
					            </b-form>
					        </validation-observer>
					        <div class="three-boxs d-flex flex-wrap">
					        	<div class="item">
					        		<div>Launched Programmes</div>
					        		<div>{{ detail.programmes_lunched }}</div>
					        	</div>
					        	<div class="item">
					        		<div>Developing Programmes</div>
					        		<div>{{ detail.total_programmes_developing }}</div>
					        	</div>
					        	<div class="item">
					        		<div>Yet to Launch</div>
					        		<div>{{ detail.total_programmes_yet_to_launch }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #F73DA1">Goals</div>
					        		<div>{{ detail.total_goal }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #F73DA1">Strategies</div>
					        		<div>{{ detail.total_stratery }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #F73DA1">Programmes</div>
					        		<div>{{ detail.total_program }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #29D3B5">Goals Achieved</div>
					        		<div>{{ detail.total_programmes_achieved }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #29D3B5">Strategies Achieved</div>
					        		<div>{{ detail.total_stratery_achieved }}</div>
					        	</div>
					        	<div class="item">
					        		<div style="background-color: #29D3B5">Programmes Achieved</div>
					        		<div>{{ detail.total_programmes_achieved }}</div>
					        	</div>
					        </div>
			            </b-tab>
			            <b-tab title="G/S/P" class="tab-child tab-pt-0">	            	
							<b-tabs card class="caption-add table-custom" v-model="tabIndex_child">
							 	<b-tab title="Goals" rel="goalTab" @click="loadListGoal">
								 	<template v-if="this.$route.name == 'itees-strategic-detail-addgoal'">
							 			<addGoal />
							 		</template>
							 		<template v-else-if="this.$route.name == 'itees-strategic-edit-goal' || this.$route.name == 'itees-strategic-detail-goal' ">
							 			<editGoal />
							 		</template>
							 		<template v-else>
							 			<b-card class="px-table-0 mt-1 w-30-percent-cl-2">
							                <b-row class="align-center py-2">
									            <b-col lg="6" style="padding-left: 33px ">
									                <h2 class="mb-0"></h2>
									            </b-col>
									            <b-col lg-6>
									            	<div class="text-right mr-2">
									            		<template v-if="this.$route.name == 'itees-strategic-edit'">
									            			<template v-if="(userData && permission.edit)">
									            				<b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click="addGoals">
											                        Add Goal
											                    </b-button> 
									            			</template>
									            			<template v-else>
									            				<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
											                        Add Goal
											                    </b-button>
									            			</template>
									            		</template>
									            		<template v-else>
									            			<template v-if="(userData && permission.edit)">
											                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEditTab(detail.id, 1)">
											                        Add Goal
											                    </b-button>
											                </template>  
											                <template v-else>
											                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
											                        Add Goal
											                    </b-button>
											                </template>   
									            		</template>           		  
									                </div>
									            </b-col>
									        </b-row>	
										    <b-table small :fields="fields_goal" :items="goals" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="sortClicked"  @row-clicked="detailGoal">
										    	<template #head(id)="data">
										    		<span>{{ data.label }}</span>
										    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc' }]" style="position: relative; left: 30px">
											        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
											        </span>
										    	</template>
										    	<template #head()="data">
										    		<span class="d-block text-center">{{ data.label }}</span>
										    	</template>
										    	<template #head(attach_name)="data">
										    		<span class="d-block text-left">{{ data.label }}</span>
										    	</template>
										    	<template #cell(attach_name)="data">
													<span class="maxTwoRow">{{ data.item.attach_name }}</span>
												</template>								    	
										    	<template #cell(id)="data">
													<span>{{ data.item.id }}</span>
												</template>	
												<template #cell(attach_status)="data">
													<span class="d-block text-center" v-if="data.item.attach_status == 0">Active</span>
													<span class="d-block text-center" v-else-if="data.item.attach_status == 1">Inactive</span>
													<span class="d-block text-center" v-else-if="data.item.attach_status == 2">Completed</span>
												</template>	
												<template #cell(action)="data">
													<div class="d-block text-center">
														<template v-if="(userData && permission.edit)">

															<span class="px-05 cursor-pointer" @click.stop="showCloneAction(data.item.id, data.item.attach_name)">
																<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM13.59 4.59L18.42 9.42C18.79 9.79 19 10.3 19 10.83V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H12.17C12.7 4 13.21 4.21 13.59 4.59ZM13 11H17.5L12 5.5V10C12 10.55 12.45 11 13 11Z" fill="#0F1EAE"/>
																</svg>
															</span>
															<span class="px-05 cursor-pointer" @click.stop="goal_edit(data.item.id)">
																<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
																</svg>
															</span>
															<template v-if="userData">
																<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.attach_name)">
																	<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
																	</svg>
																</span>
															</template>
															<template v-else>
																<span class="px-05">
																	<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
																	</svg>
																</span>
															</template>

														</template>
														<template v-else>
															<span class="px-05">
																<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM13.59 4.59L18.42 9.42C18.79 9.79 19 10.3 19 10.83V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H12.17C12.7 4 13.21 4.21 13.59 4.59ZM13 11H17.5L12 5.5V10C12 10.55 12.45 11 13 11Z" fill="#b1b1b1"/>
																</svg>
															</span>
															<span class="px-05">
																<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
																</svg>
															</span>
															<span class="px-05">
																<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
																</svg>
															</span>
														</template>
													</div>										
												</template>		
												<template #cell(strategies)="data">
													<div class="d-block text-center" v-if="data.item.strategic_count_count != 0">
														<div class="text-center d-block cursor-pointer decoration-hover" style="color: #8471FF" @click.stop="showStraList_goal(data.item.id)">
															List
														</div>
													</div>
													<span class="d-block text-center" v-else>-</span>	
												</template>
												<template #cell(programs)="data" v-model="programmeList">
													<template v-if="data.item.strategic_count_count != 0 && (data.item.strategic_count.filter(item=>item.programme_count)).length > 0">
														<div class="d-block text-center">
															<div class="text-center d-block cursor-pointer decoration-hover" style="color: #8471FF" @click.stop="showProgrammeList_goal(data.item.id)">
																List
															</div>
														</div>	
													</template>	
													<span class="d-block text-center" v-else>-</span>										
												</template>
												<template #cell()="data">
													<span class="d-block text-center">{{ data.value }}</span>
												</template>				
												<template #empty="scope">
											      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
											    </template>
											</b-table>
										</b-card>
										<div v-if="this.total > 0" class="paging-cus relative">
												<b-pagination
													v-model="currentPage"
													:total-rows="total"
													:per-page="perPage"
													aria-controls="custom-table"
													first-number
													last-number
													class="just-center"
												></b-pagination>
												<div class="limit-per">
													<select v-model="selected" class="cursor-pointer">
														<option v-for="limit in limitpage">{{ limit }}</option>
													</select>			  
												</div>
										</div> 
						                <b-modal id="modalList_pa" ref="strategies_list" title="Strategies list" hide-header-close ok-title="Save" centered>
						                    <div class="list-content">
												<b-col lg="12" class="px-0 cb-stra-group" v-if="count_goals_stra_list > 0 ">
													<b-form-group class="title-content" label="Strategy" label-cols-lg="2" v-slot="{ editStraChecked }">
															<b-form-checkbox-group
																v-model="selectedStra"
																:options="goals_stra_list"
																value-field="id"
																text-field="attach_name"
																class='bold'
															/>
													</b-form-group>                
												</b-col>
												<b-col lg="12" class="px-0 cb-stra" v-else>
													<b-form-group label="Strategy" label-cols-lg="2" >
														<div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 430px; padding: 6px 10px ">No Data ! You can only select after a Strategy is created.</div> 
													</b-form-group>
												</b-col>
						                    </div>   
						                    <template #modal-footer="{ cancel }">
						                        <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
						                          	Close
						                        </b-button>                    
						                    </template>             
						                </b-modal>
						                <b-modal id="modalList_1_pa" ref="programme_list_goals" title="Programmes list" hide-header-close ok-title="Save" centered>
											<div class="list-content">
												<b-col lg="12" class="px-0 cb-stra-group" v-if="count_goals_programme_list > 0 ">
													<b-form-group class="title-content" label="Programmes" label-cols-lg="2" v-slot="{ editStraChecked }">
															<b-form-checkbox-group
																v-model="selectedPro"
																:options="goals_programme_list"
																value-field="id"
																text-field="name"
																class='bold'
															/>
													</b-form-group>                
												</b-col>
												<b-col lg="12" class="px-0 cb-stra" v-else>
													<b-form-group label="Strategy" label-cols-lg="2" >
														<div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 430px; padding: 6px 10px ">No Data ! You can only select after a Strategy is created.</div> 
													</b-form-group>
												</b-col>
						                    </div>  
						                    <template #modal-footer="{ cancel }">
						                        <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
						                          	Close
						                        </b-button>                    
						                    </template>             
						                </b-modal>
							 		</template>	                
							 	</b-tab>
							 	<b-tab title="Strategies" @click="loadListStrategies">
							 		<Strategies />
							 	</b-tab>
							 	<b-tab title="Programmes" @click="loadListProgrammes">
							 		<Programme />
							 	</b-tab>
							</b-tabs>
			            </b-tab>                      
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb';
	import Datepicker from 'vuejs-datepicker';
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import Strategies from './tabs/tabStrategies'
    import Programme from './tabs/tabProgramme'

    import addGoal from './goals/Add'
    import editGoal from './goals/Edit'

    const moment = require('moment');

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,

          	Strategies,
          	Programme,
          	addGoal,
          	editGoal,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "itees-strategic-edit": "" }, 
				name_sublabel: { "itees-strategic-detail-addgoal": "" }, 
				isEditing: true,
			    detail: [],	  
				permission: [],

			    selectStatus: [
			    	{title: 'Active', value: 0},
			    	{title: 'Inactive', value: 1},
			    	{title: 'Completed', value: 2},
			    ],  
			    statusDetailText: {
			   		title: null,
			   		value: null
			   	},
			   	isDisabled: true,
				isEditing: true,
			   	disableField: 'disabled-field',
			   	fields_goal: [
                	{ key: 'id', label: 'GID.' },
			        { key: 'attach_name', label: 'Goal Name' },			        
			        { key: 'strategies', label: 'Strategies' },
			        { key: 'programs', label: 'Programmes' },
					{ key: 'attach_status', label: 'Status' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	goals: [],	
		    	sort: {
		      		"field": "id",
		      		"type": "asc"
		      	},
		      	fields_goals_stra_list: [
                	{ key: 'no', label: 'No.' },
			        { key: 'attach_name', label: 'Activity Listing' },			        
			        { key: 'action', label: 'Actions' },			
			    ],   
			    goals_stra_list: [],
				count_goals_stra_list: 0,
				selectedStra: [],
			    fields_goals_programme_list: [
                	{ key: 'no', label: 'No.' },
			        { key: 'name', label: 'Activity Listing' },			        
			        { key: 'action', label: 'Actions' },			
			    ],   
			    goals_programme_list: [],	
				count_goals_programme_list: 0,	  
				selectedPro: [],  
			    // 
		        tabIndex_parent: 0,
		        tabIndex_child: 0,
		        // disableStraTab: false,
		        // disableProTab: false,
		        programsList: null,

				perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10'
            }	
		},
		watch: {
			"$route": function(val) {			
				if(val) {
					if(val.name == 'itees-strategic-edit'){
						this.isDisabled = false
            			this.disableField = ''
            			this.labels = {'itees-strategic-edit': ''}
            			this.fetchItem()
					}
					if(val.name == 'itees-strategic-detail-addgoal' ){
						this.name_sublabel = { "itees-strategic-detail-addgoal": "" }
						this.fetchItemgetName()
					}
					else if(val.name == 'itees-strategic-edit-goal' ){
						this.fetchItemgetName_edit()
						this.fetchItemgetNameGoal_edit()
						this.labels = { "itees-strategic-edit-goal": "" }
						this.name_sublabel = { "itees-strategic-edit-goal": "" }
					}
					else if(val.name == 'itees-strategic-detail-goal' ){
						this.fetchItemgetName_edit()
						this.fetchItemgetNameGoal_edit()
						this.labels = { "itees-strategic-detail-goal": "" }
						this.name_sublabel = { "itees-strategic-detail-goal": "" }
					}
					else if(val.name == 'itees-strategic-detail-addstrategy' ){
						this.labels = { "itees-strategic-detail-editstra": "" }
						this.name_sublabel = { "itees-strategic-detail-addstrategy": "" }
						this.setGoalNameWhenAddStra()
					}
					else if(val.name && val.name == 'itees-strategic-detail-editstra' ){
						this.getNameStra_edit()
						this.getNameStra_edit_labels()
						this.labels = { "itees-strategic-detail-editstra": "" }
						this.name_sublabel = { "itees-strategic-detail-editstra": "" }
					}
					else if(val.name && val.name == 'itees-strategic-detail-stra' ){
						this.getNameStra_edit()
						this.getNameStra_edit_labels()
						this.labels = { "itees-strategic-detail-stra": "" }
						this.name_sublabel = { "itees-strategic-detail-stra": "" }
					}
					else if(val.name == 'itees-strategic-detail-addprogramme' ){
						this.name_sublabel = { "itees-strategic-detail-addprogramme": "" }
						this.setGoalNameWhenAddPro()
					}
					else if(val.name == 'itees-strategic-detail-editprogramme' ){
						this.getPlanByPro()
						this.setGoalNameWhenEditPro()
						this.labels = { "itees-strategic-detail-editprogramme": "" }
						this.name_sublabel = { "itees-strategic-detail-editprogramme": "" }
					}
					else if(val.name == 'itees-strategic-detail-programme' ){
						this.getPlanByPro()
						this.setGoalNameWhenEditPro()
						this.labels = { "itees-strategic-detail-programme": "" }
						this.name_sublabel = { "itees-strategic-detail-programme": "" }
					}
					else{
						this.fetchItem()
					}
				}

				if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex_parent = Number(this.$route.query.tab)	
				if(this.$route && this.$route.query && this.$route.query.child) this.tabIndex_child = Number(this.$route.query.child)

				this.fetchItemGoals()
			},
			currentPage(val){
				this.fetchItemGoals()
			},
			selected(val){
				if(val) {
					this.perPage = val
					this.fetchItemGoals()
				}
			}
		},		
		created(){
			if(this.$route.name == 'itees-strategic-detail'){
				this.labels = {'itees-strategic-detail': ''}
			}
			if(this.$route &&  this.$route.name == 'itees-strategic-edit'){
				this.isDisabled = false
            	this.disableField = ''
            	this.labels = {'itees-strategic-edit': ''}
			}
			if(this.$route && this.$route.query && this.$route.query.tab) this.tabIndex_parent = Number(this.$route.query.tab)
			if(this.$route && this.$route.query && this.$route.query.child) this.tabIndex_child = Number(this.$route.query.child)

			this.fetchItem()
			this.fetchItemGoals()
			this.checkPermission("Strategic Plan")

			if(this.$route.name && this.$route.name == 'itees-strategic-detail-addgoal' ){
				this.name_sublabel = { "itees-strategic-detail-addgoal": "" }
				this.fetchItemgetName()
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail' ){
				this.fetchItemgetName_edit()
				this.fetchItemgetNameGoal_edit()
				this.labels = { "itees-strategic-detail": "" }
				this.name_sublabel = { "itees-strategic-detail": "" }
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-goal' ){
				this.fetchItemgetName_edit()
				this.fetchItemgetNameGoal_edit()
				this.labels = { "itees-strategic-detail-goal": "" }
				this.name_sublabel = { "itees-strategic-detail-goal": "" }
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-edit-goal' ){
				// this.disableTabOnAddGoal = true
				this.fetchItemgetName_edit()
				this.fetchItemgetNameGoal_edit()
				this.labels = { "itees-strategic-edit-goal": "" }
				this.name_sublabel = { "itees-strategic-edit-goal": "" }
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-addstrategy' ){
				this.labels = { "itees-strategic-detail-addstrategy": "" }
				this.name_sublabel = { "itees-strategic-detail-addstrategy": "" }
				this.setGoalNameWhenAddStra()
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-editstra' ){
				this.getNameStra_edit()
				this.getNameStra_edit_labels()
				this.labels = { "itees-strategic-detail-editstra": "" }
				this.name_sublabel = { "itees-strategic-detail-editstra": "" }
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-stra' ){
				this.getNameStra_edit()
				this.getNameStra_edit_labels()
				this.labels = { "itees-strategic-detail-stra": "" }
				this.name_sublabel = { "itees-strategic-detail-stra": "" }
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-addprogramme' ){
				this.labels = { "itees-strategic-detail-addprogramme": "" }
				this.name_sublabel = { "itees-strategic-detail-addprogramme": "" }
				this.setGoalNameWhenAddPro()
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-editprogramme' )
			{
				this.getPlanByPro()
				this.setGoalNameWhenEditPro()
				this.labels = { "itees-strategic-detail-editprogramme": "" }
				this.name_sublabel = { "itees-strategic-detail-editprogramme": "" }				
			}
			else if(this.$route.name && this.$route.name == 'itees-strategic-detail-programme' )
			{
				this.getPlanByPro()
				this.setGoalNameWhenEditPro()
				this.labels = { "itees-strategic-detail-programme": "" }
				this.name_sublabel = { "itees-strategic-detail-programme": "" }				
			}
			else{
				// this.disableTabOnAddGoal = false
			}
		},
		methods: {			
			toEdit(id){
				this.$router.push(`/admin/itees-strategic-plan/edit/${id}`)
			},
			toEditTab(id, tabIndex){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${id}/add-goal`, query: {tab: tabIndex} })
			},
			length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
			checkPermission(namePage){
				var action = [];
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
				})
				this.permission = action 
			},
			fetchItem(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {					
					document.title = response.data.data.name + ' - ITEES | Your Global Partner of Choice'
					this.detail = response.data.data
					this.labels['itees-strategic-detail'] = this.detail.name
					this.labels['itees-strategic-edit'] = this.detail.name
					this.detail.start_year = String(this.detail.start_year)
					this.detail.end_year = String(this.detail.end_year)

					if(this.detail.status == 0) {
						this.statusDetailText.title = 'Active'
						this.statusDetailText.value = 0					
					}
					if(this.detail.status == 1) {						
						this.statusDetailText.title = 'Inactive'			
						this.statusDetailText.value = 1			
					}
					if(this.detail.status == 2) {						
						this.statusDetailText.title = 'Completed'			
						this.statusDetailText.value = 2			
					}
				})
				.catch((error) => {
					console.log(error)
				})
			},
			// get 1 sublabel - goal
			fetchItemgetName(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {	
					this.name_sublabel['itees-strategic-detail-addgoal'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			// get 2 sublabel edit
			fetchItemgetName_edit(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {						
					this.name_sublabel['itees-strategic-edit-goal'] = response.data.data.name
					this.name_sublabel['itees-strategic-detail-goal'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			fetchItemgetNameGoal_edit(){
				this.$store
				.dispatch('project/detailGoalStra', {
					id: this.$route.params.goal_id	
				})
				.then(response => {	
					this.labels['itees-strategic-edit-goal'] = response.data.data.attach_name
					this.labels['itees-strategic-detail-goal'] = response.data.data.attach_name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			// add new Strategy
			setGoalNameWhenAddStra(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {	
					this.name_sublabel['itees-strategic-detail-addstrategy'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getNameStra_edit(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {						
					this.name_sublabel['itees-strategic-detail-editstra'] = response.data.data.name
					this.name_sublabel['itees-strategic-detail-stra'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getNameStra_edit_labels(){
				this.$store
				.dispatch('project/detailGoalStra', {
					id: this.$route.params.stra_id	
				})
				.then(response => {	
					this.labels['itees-strategic-detail-editstra'] = response.data.data.attach_name
					this.labels['itees-strategic-detail-stra'] = response.data.data.attach_name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			// programme
			setGoalNameWhenAddPro(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {	
					this.name_sublabel['itees-strategic-detail-addprogramme'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getPlanByPro(){
				this.$store
				.dispatch('project/detailStrategicPlan', {
					id: this.$route.params.id	
				})
				.then(response => {						
					this.name_sublabel['itees-strategic-detail-editprogramme'] = response.data.data.name
					this.name_sublabel['itees-strategic-detail-programme'] = response.data.data.name
					// console.log('name edit: ', this.name_sublabel)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			setGoalNameWhenEditPro(){
				this.$store
				.dispatch('project/detailProgrammeItem', {
					id: this.$route.params.pro_id	
				})
				.then(response => {	
					this.labels['itees-strategic-detail-editprogramme'] = response.data.data.name
					this.labels['itees-strategic-detail-programme'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelEdit(){
				this.isEditing = true
                this.$router.push({ name: 'itees-strategic' })
                this.fetchItem()
			},
			saveEdit(){
				this.$refs.form_rel.validate().then(success => {
					if (success){
						let start_year_format = moment(this.detail.start_year).format('yyyy')
						let end_year_format = moment(this.detail.end_year).format('yyyy')

						if(parseInt(end_year_format - start_year_format) > 0){
							let fields = {
			                  	name: this.detail.name,
			                  	start_year: start_year_format,         
			                  	end_year: end_year_format, 
			                  	status: this.statusDetailText.value,
			                  	description: this.detail.description
			                }
			                // console.log('fields: ', fields)
			                this.$store
							.dispatch('project/editStrategicPlan', 
								{
									id: this.$route.params.id,
									data: fields
								}
							)
							.then(response => {
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: fields.name + ' Updated!',
				                          variant: 'success',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
				                this.$router.push({ name: 'itees-strategic' })
							})
							.catch((error) => {
								console.log(error)
								this.$toast({
				                      	component: ToastificationContent,
				                      	props: {
				                          title: error.response.data.message,
				                          variant: 'danger',
				                      	},
				                	},
				                	{
				                      	position: 'top-center'
				                	}
				                )
							})
						}
						else{
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: 'End Year must be greater than Start Year',
			                          variant: 'danger',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )
						}
						
					}
				})
			},
			fetchItemGoals(){
				this.$store
				.dispatch('project/fetchStrategicPlan_GoalsStra', {
					filter: 
					{
					  "attachable_id": this.$route.params.id,
					  "count":"strategic",
					  "list_search": [
					   {
					      "field": "attachable_type",
					      "keyword": "goals"
					    }
					  ]
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort
				})
				.then(response => {		
					this.goals = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			showStraList_goal(id_item){
				this.count_goals_stra_list = 0
				this.fetchItemGoals_item_listStra(id_item)
				this.$refs['strategies_list'].show()
			},
			fetchItemGoals_item_listStra(id_item){
				this.$store
				.dispatch('project/fetchStrategicPlan_GoalsStra', {
					filter: {
					  	"attachable_id": this.$route.params.id,
						  "list_search": [
						    {
						      "field": "goal_id",
						      "keyword": id_item
						    }
						  ]
					},
				})
				.then(response => {	
					this.goals_stra_list = response.data.data
					this.count_goals_stra_list = this.goals_stra_list.length

					this.selectedStra = this.goals_stra_list.filter(
						item => item.attach_stratery_id == id_item
					).map(item => item.id) // map ra field id
				})
				.catch((error) => {
					console.log(error)
				})
			},	
			showProgrammeList_goal(id_item){
				this.count_goals_programme_list = 0
				this.fetchItemGoals_item_programme(id_item)
				this.$refs['programme_list_goals'].show()
			},		
			fetchItemGoals_item_programme(id_item){
				this.$store
				.dispatch('project/fetchStrategicPlan_ProgrammeList', {
					filter: 
					{  "strategic_plan": this.$route.params.id, 
						"list_search": [
							{ "field": "goal_id","keyword": id_item }
						]
					}
				})
				.then(response => {	
					this.goals_programme_list = response.data.data
					this.count_goals_programme_list =  this.goals_programme_list.length	  
					this.selectedPro = this.goals_programme_list.filter(
						item => item.id != 0
					).map(item => item.id)  
				})
				.catch((error) => {
					console.log(error)
				})
			},	
			sortClicked(key){
				if(this.sort.field == key){
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				}
				else{					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchItemGoals(); 
			},
			addGoals(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/add-goal`, query: {tab: 1} })
			},
			detailGoal(goal) {
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/goal/${goal.id}`, query: {tab: 1} })
			},
			goal_edit(id){	
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/goal/edit/${id}`, query: {tab: 1} })
			},	
			loadListGoal(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1} })
			},
			loadListStrategies(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 1} }) 
			},
			loadListProgrammes(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 2} })
			},
			showCloneAction(id, name){
				this.$bvModal.msgBoxConfirm('Please confirm that you want to copy.', {
		          title: 'Please Confirm',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'YES',
		          cancelTitle: 'NO',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.copyAction(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			copyAction(id, title){
				this.$store
				.dispatch('project/copyGloalStra', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Duplicated!',
	                          variant: 'success',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItemGoals()	                			
				})
				.catch((error) => {
					console.log(error)
				})
			},
			showDeleteAction(id, name){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteItem(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteItem(id, title){
				this.$store
				.dispatch('project/deleteStrategicItem', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItemGoals()	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#modalList_pa .modal-dialog .row-container,
	#modalList_1_pa .modal-dialog .row-container
	{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#modalList_pa .modal-dialog .modal-body,
	#modalList_1_pa .modal-dialog .modal-body
	{
		padding-top: 0;
    	padding-bottom: 0;
	}
	#modalList_pa .modal-dialog .modal-footer,
	#modalList_1_pa .modal-dialog .modal-footer
	{
		padding-bottom: 30px; 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}

	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	#modalList_pa .modal-dialog, #modalList_1_pa .modal-dialog{
		max-width: 1084.38px !important;
	}
	.list-content{
		padding-left: 60px;
	}
	.list-content .title-content legend{
		font-size: 16px;
		font-weight: 700 !important;
	}
	.list-content .bold{
		pointer-events: none;
	}
</style>