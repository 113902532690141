<template>
	<section>
    <validation-observer ref="form_rel" #default="{invalid}" >
        <b-form @submit.prevent class="profile-frm pb-0 pt-0 mb-2" style="border-radius: 5px;     box-shadow: 0px 0 2px rgb(0 0 0 / 20%) ">
            <b-row class="align-center py-2">
                <b-col lg-12>
                  <div class="text-right">
                      <template v-if="(userData && permission.edit)">

                          <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelProgramme" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
                          <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="saveProgramme">
                                  Save
                          </b-button>     
                      </template>
                      <template v-else>
                            <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                                Edit
                            </b-button>  
                        </template>
                  </div>
                </b-col>
            </b-row>  
            <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
            <div class="frm-basic size-16 black-child d-block">
              <b-row style="width: 100%; margin: 0;">  
                <b-col lg="12" class="px-0">
                    <b-form-group label="Programme Name" label-cols-lg="2" class="star-required">     
                      <validation-provider
                        #default="{ errors }"
                        name="Programme Name"
                        vid="name"
                        rules="required"                      
                      >                        
                          <b-form-input
                              v-model="detail.name"
                              placeholder="Name"
                              :formatter="length100"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Status" label-cols-lg="4" class="star-required">
                    <validation-provider
                        #default="{ errors }"
                        name="Status"
                        vid="status"
                        rules="required"                      
                      >
                        <v-select
                            v-model="status_edit"
                            label="title"
                            :options="selectStatusProgramme"
                            placeholder="Select Status"
                          />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Last Update" label-cols-lg="4" class="star-required">
                      <flat-pickr
                          v-model="detail.last_update"
                          class="form-control" 
                          :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
                          placeholder="dd/mm/yy"
                          disabled
                      />
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Strategy" label-cols-lg="4">
                      <v-select
                          v-model="strategyName"
                          label="attach_name"
                          :options="selectStrategy"
                          placeholder="Select Strategy"
                        />
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Goal" label-cols-lg="4">              
                      <b-form-input
                          v-model="detail.goal_name"
                          readonly
                      ></b-form-input>
                  </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <b-form-group label="Programme Description" label-cols-lg="2">     
                      <validation-provider
                        #default="{ errors }"
                        name="Programme Description"
                        vid="description"                     
                      >                        
                          <b-form-textarea
                            v-model="detail.description"
                              placeholder="Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col> 
                 <b-col lg="6" class="px-0">
                    <b-form-group label="Key Performance Indicators (KPI)" label-cols-lg="4">     
                      <validation-provider
                        #default="{ errors }"
                        name="Key Performance Indicators (KPI)"
                        vid="key_performance"                      
                      >                        
                          <b-form-textarea
                            v-model="detail.kpi_description"
                              placeholder="KPI Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col lg="6" class="px-0">
                    <b-form-group label="KPI Achievements" label-cols-lg="4">     
                      <validation-provider
                        #default="{ errors }"
                        name="KPI Achievements"
                        vid="achievements"                     
                      >                        
                          <b-form-textarea
                            v-model="detail.kpi_achievement_description"
                              placeholder="KPI Achievement Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col lg="12" class="px-0">
                    <div style="border-top: 1px solid #B1B1B1;" class="pb-2"></div>
                </b-col>
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Assign Staff" label-cols-lg="4" class="star-required">
                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray btn-staff white-color-child" style="max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) " @click="addStaff">
                        <span v-if="staff_selected_array.length > 0">Edit Staff</span>
                        <span v-else>Add Staff</span>
                    </b-button>      
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Launched Date" label-cols-lg="4" class="star-required">
                    <div class="calendarIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                    </div>                            
                          <flat-pickr
                              v-model="detail.launched_date"
                              placeholder="dd/mm/yyyy"
                              class="form-control fieldCalendar"           
                              :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
                              required
                          />
                  </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <b-form-group label="Staff Assigned" label-cols-lg="2" class="star-required">     
                        <div class="staff-selected disabled-field">
                          <div v-if="selectedStaff">
                              {{ selectedStaff.map(item=>item.full_name).join(", ") }}
                          </div>                                
                        </div>

                    </b-form-group>
                </b-col>  
                <b-col lg="6" class="px-0">          
                  <b-form-group label="Status update" label-cols-lg="4">
                      <template v-if="detail.status == 1">
                           <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) " @click="addStatusUpdate">
                                Add
                          </b-button> 
                      </template>
                      <template v-else>
                           <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #B1B1B1 !important; border-color: #B1B1B1 !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); opacity: 1; " v-b-tooltip.hover title="The Programme status must be is Launch">
                                Add
                          </b-button>
                      </template>                          
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-modal
              ref="add_staff"
              title="Add Staff" 
              :no-close-on-backdrop="true" 
              hide-header-close 
              id="addStaff_modal">
                <b-row style="padding-right: 30px">                
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Select Staff" label-cols-xl="3"
                          label-cols-md="3" label-class="text-lg-right bold">
                            <validation-provider
                              #default="{ errors }"
                              name="Select Staff"
                              vid="time_schedule"
                              rules="required"               
                            >   
                              <v-select
                                v-model="selectedStaff"
                                :close-on-select="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="full_name"
                                :options="optionsStaff"
                                placeholder="Select Staff"
                                class="timebox form-control px-0 border-none h-auto red-option-selected"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>   
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Staff Selected" label-cols-xl="3"
                          label-cols-md="3" label-class="text-lg-right bold">
                              <div class="staff-selected disabled-field">
                                <div v-if="selectedStaff">
                                    {{ selectedStaff.map(item=>item.full_name).join(", ") }}
                                </div>                                
                              </div>
                        </b-form-group>
                    </b-col>
                </b-row>   
                <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="static" class="btn-default" @click="cancelStaff">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" class="btn-primary" @click="saveStaff">
                        Save
                    </b-button>
                </template> 
            </b-modal>
            <b-modal
              ref="add_status"
              title="Add Status Update" 
              :no-close-on-backdrop="true"   
              id="addStatus_modal">
                <b-row style="padding-right: 30px">
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Updates information" label-cols-xl="3"
                          label-cols-md="3" label-class="text-lg-right bold">
                              <b-form-textarea
                                  v-model="status_udpate"
                                  placeholder="Text"
                                  rows= "4"
                                  :formatter="length1000"
                              ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>   
                <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="static" class="btn-default" @click="cancelStatus">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" class="btn-primary" @click="saveStatus">
                        Save
                    </b-button>
                </template> 
            </b-modal>
        </b-form>
    </validation-observer>
    <template v-for="(item, index) in status_update_list">
          <b-card class="mb-1 py-1 card-body-p0" style="box-shadow: 0px 0 2px rgb(0 0 0 / 20%) !important">
                <b-row>
                  <b-col xl="2" lg="3" class="date_added">{{ item.create_at }}</b-col>
                  <b-col xl="2" lg="3">{{ item.user_id }}</b-col>
                  <b-col xl="8" lg="6">
                      {{ item.content }}
                  </b-col>
                </b-row>
          </b-card>
      </template>
	</section>
</template>

<script>

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import flatPickr from 'vue-flatpickr-component'
    import Multiselect from 'vue-multiselect'
    import globalAdmin from '../../model/globalAdmin';
    const moment = require('moment');

	export default {
      mixins: [globalAdmin],
	  	components: {
        	vSelect,
        	ValidationProvider,
        	ValidationObserver,
          Multiselect,
          flatPickr
	  	},
	  	data() {
    		  return {
              userData: JSON.parse(localStorage.getItem('userData')),
              detail: [],
              selectStrategy: [],
              selectStatusProgramme: [
                {title: 'Yet to Launch', value: 0}, 
                {title: 'Launched', value: 1},          
                {title: 'Developing', value: 2},
              ],   
              dir: 'ltr',
              permission: [],
              selectedStaff: [],
              optionsStaff: [],
              staff_selected_array: [],
              status_edit: {
                title: null,
                value: null
              },
              strategyName: {
                attach_name: 'Select Strategy',
                id: null
              },
              isEditing: true,
		          isDisabled: true,
              disableField: 'disabled-field',
              status_update_list: [],
              status_udpate: null
          }	
		},	
    watch: {            
        'strategyName'(val) {
            if(val){
              if(val.goal && val.goal != null){
                this.detail.goal_name = val.goal.attach_name
              }
              else this.detail.goal_name = null
            }
            else this.detail.goal_name = null
        }
    },
		created(){
        this.permission = this.checkPermission(this.userData,"Strategic Plan");
        this.listStaff()
        this.detailProgramme()
        this.listStrategy()
        this.getStatusUpdate()
        this.getStaffSelected()
		},
		methods: {	
      length1000(e){
          return String(e).substring(0,1000)
      },
      length100(e){
          return String(e).substring(0,100)
      }, 		
      detailProgramme(){
          this.$store
          .dispatch('project/detailProgrammeItem', {
              id: this.$route.params.pro_id
          })
          .then(response => {

              this.detail = response.data.data

              if(this.detail.status == 0) {
                this.status_edit.title = 'Yet to Launch'
                this.status_edit.value = 0         
              }
              if(this.detail.status == 1) {           
                this.status_edit.title = 'Launched'      
                this.status_edit.value = 1     
              }
              if(this.detail.status == 2) {           
                this.status_edit.title = 'Developing'     
                this.status_edit.value = 2     
              }              
              if(this.detail.stratery_id != null){
                this.getStrabyID(this.detail.stratery_id)
              }

              this.selectedStaff = this.detail.staff_assign.map((item) => {
                return{
                  id: item.user_id,
                  full_name: item.user_programme_with_trashed ? item.user_programme_with_trashed.full_name : null
                }
              })              
              this.staff_selected_array = this.selectedStaff.map(item => item.id )
              this.status_update_list = this.detail.status_update

              // console.log('this.selectedStaff: ', this.selectedStaff)
          })
          .catch((error) => {
            console.log(error)
          })
      },   
      listStrategy(){
          this.$store
          .dispatch('project/fetchStrategicPlan_GoalsStra', { 
              filter: {
                "attachable_id": this.$route.params.id,
                "list_search": [
                 {
                    "field": "attachable_type",
                    "keyword": "stratery"
                  }
                ]
              }
          })
          .then(response => {
              this.selectStrategy = response.data.data
              // console.log('stra list: ', this.selectStrategy)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getStrabyID(id_stra){
        this.$store
          .dispatch('project/detailGoalStra', {
              id: id_stra
          })
          .then(response => {

              this.strategyName.id = response.data.data.id
              this.strategyName.attach_name = response.data.data.attach_name
              // console.log(this.strategyName)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      listStaff(){
          this.$store
          .dispatch('auth/fetch_staff_log_list', {
              filter: {
                "group":"1",
                "role":"4"
              }
          })
          .then(response => {
              this.optionsStaff = response.data.data.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      editAction(){
        this.isDisabled = false
		    this.isEditing = false
		    this.disableField = ''
      },
      saveProgramme(){
          this.$refs.form_rel.validate().then(success => {
            if (success){
              if(this.staff_selected_array && this.staff_selected_array.length > 0){
                let data = {
                    name: this.detail.name,
                    status: this.status_edit.value,  
                    stratery_id: this.strategyName ? this.strategyName.id : null,
                    description: this.detail.description,
                    kpi_description: this.detail.kpi_description,
                    kpi_achievement_description: this.detail.kpi_achievement_description,
                    launched_date: this.detail.launched_date,
                    staff_assign: this.staff_selected_array,
                }
                this.$store
                .dispatch('project/editProgramme', {
                    id: this.$route.params.pro_id,
                    data: data
                })
                .then(response => {
                    this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.detail.name + ' Updated!',
                            variant: 'success',
                          },
                      },
                      {
                          position: 'top-center'
                      }
                    )
                    this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 2} })                    
                })
                .catch((error) => {
                  console.log(error)
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.message,
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-center'
                    }
                  )
                })  
              }
              else{
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Please click "Add Staff" before Save',
                        variant: 'success',
                      },
                  },
                  {
                      position: 'top-center'
                  }
                )
              }
            }
          })
      },
      cancelProgramme(){
          this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 2} })
      },
      getStaffSelected(){
          this.$store
          .dispatch('project/detailProgrammeItem', {
              id: this.$route.params.pro_id
          })
          .then(response => {
              this.selectedStaff = response.data.data.staff_assign.map((item) => {
                return{
                  id: item.user_id,
                  full_name: item.user_programme_with_trashed ? item.user_programme_with_trashed.full_name : null
                }
              })              
              this.staff_selected_array = this.selectedStaff.map(item => item.id )
          })
          .catch((error) => {
            console.log(error)
          })
      }, 
      addStaff(){
          this.$refs['add_staff'].show()
      },
      cancelStaff(){
          this.$refs['add_staff'].hide()
          // console.log('staff list: ', this.selectedStaff)
          this.getStaffSelected()
      },
      saveStaff(){
          this.staff_selected_array = this.selectedStaff.map(item => item.id )
          this.$refs['add_staff'].hide()
      },
      addStatusUpdate(){
          this.$refs['add_status'].show()
          this.status_udpate = null
      },
      cancelStatus(){
          this.$refs['add_status'].hide()
      },
      saveStatus(){
          this.setStatusUpdate()
          this.$refs['add_status'].hide()
      },
      getStatusUpdate(){
          this.$store
          .dispatch('project/detailProgrammeItem', {
              id: this.$route.params.pro_id
          })
          .then(response => {
              this.status_update_list = response.data.data.status_update
          })
          .catch((error) => {
            console.log(error)
          })
      },   
      setStatusUpdate(){
        // console.log(this.status_udpate)
        let data = {
            content: this.status_udpate    
        }
        this.$store
        .dispatch('project/addStatusUpdate', {
            data: data,
            id: this.$route.params.pro_id       
        })
        .then(response => {
            this.getStatusUpdate()
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Status Has been added',
                  variant: 'success',
                },
            },
            {
                position: 'top-center'
            }
          )
        })
        .catch((error) => {
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    variant: 'danger',
                  },
              },
              {
                  position: 'top-center'
              }
            )
        })
      },
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
  #addStaff_modal .modal-dialog, #addStatus_modal .modal-dialog{
      max-width: 650px;
  }
</style>