<template>
	<section>
		  <b-card class="px-table-0 mt-1">
          <validation-observer ref="editGoalform_rel" #default="{invalid}" >
            <b-form @submit.prevent class="profile-frm pb-0 pt-0" style="border-radius: 5px">
              <b-row class="align-center py-2">
                  <b-col lg-12>
                    <div class="text-right">
                      <template v-if="(userData && permission.edit)">

                            <template v-if="$route.name == 'itees-strategic-edit-goal'">
                                <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelEditGoal" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

                                <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="saveEditGoal">
                                    Save
                                </b-button>
                            </template>
                            <template v-else>
                                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEdit">
                                    Edit
                                </b-button>
                            </template>

                      </template>
                      <template v-else>
                          <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                              Edit
                          </b-button>  
                      </template>
                    </div>
                  </b-col>
              </b-row>  
              <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
              <div class="frm-basic size-16 black-child d-block">
                <b-row style="width: 100%; margin: 0;">  
                    <b-col lg="12" class="px-0">
                      <b-form-group label="Goal Name" label-cols-lg="2" class="star-required">     
                        <validation-provider
                          #default="{ errors }"
                          name="Goal Name"
                          vid="goal-name"
                          rules="required"      
                        >                        
                            <b-form-input
                              v-model="goal_detail_tab.attach_name"
                                placeholder="Name"
                                :formatter="length100"
                                :disabled="isDisabled"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> 
                    <b-col lg="6" class="px-0">             
                      <b-form-group label="Status" label-cols-lg="4" class="star-required">
                          <validation-provider
                            #default="{ errors }"
                            name="Status"
                            vid="goal-status"
                            rules="required"                      
                          >
                            <v-select
                              v-model="status_goal_edit"
                              label="title"
                              :options="selectStatusGoal"
                              placeholder="Select Status"
                              :disabled="isDisabled"
                              class="select-status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                    </b-col> 
                    <b-col lg="6" class="px-0">             
                        <b-form-group label="Last Update" label-cols-lg="4" class="star-required">
                          <div class="calendarIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                          </div>
                          <validation-provider
                              #default="{ errors }"
                              name="Last Update"
                              vid="last-update"
                              rules="required"                      
                            >
                                <flat-pickr
                                  v-model="goal_detail_tab.updated_at"
                                    class="form-control fieldCalendar" 
                                    :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
                                    placeholder="dd/mm/yy"
                                    disabled
                                />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> 
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Description" label-cols-lg="2" class="star-required">     
                          <validation-provider
                            #default="{ errors }"
                            name="Description"
                            vid="description"
                            rules="required"                      
                          >                        
                              <b-form-textarea
                                  v-model="goal_detail_tab.description"
                                  placeholder="Description"
                                  rows= "4"
                                  :formatter="length1000"
                                  :disabled="isDisabled"
                                  style="padding-top: 12px !important"
                              ></b-form-textarea>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </b-col> 
                    <b-col lg="12" class="px-0">

                        <div style="border-top: 1px solid #B1B1B1;" class="pb-2"></div>
                        <b-form-group label="Outcome" label-cols-lg="2" class="star-required">     
                          <validation-provider
                            #default="{ errors }"
                            name="Outcome"
                            vid="goal-des"
                            rules="required"                      
                          >                        
                              <b-form-textarea
                                  v-model="goal_detail_tab.attach_outcome"
                                  placeholder="Outcome"
                                  rows= "4"
                                  :formatter="length1000"
                                  :disabled="isDisabled"  
                                  style="padding-top: 12px !important"
                              ></b-form-textarea>
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                    </b-col> 
                    <b-col lg="12" class="px-0 cb-stra-group-cb" v-if="countStraDetailList > 0 ">
                      <b-form-group label="Strategy" label-cols-lg="2" v-slot="{ editStraChecked }">
                          <b-form-checkbox-group v-model="selectedStraDetail" >
                              <b-form-checkbox
                                v-for="option in optionsStraDetail"
                                :key="option.id"
                                :value="option.id"
                                :aria-describedby="editStraChecked"
                                class='bold'
                                name="cb_strategy"
                                :disabled="isdisabledCb(option) || isDisabled"
                              >
                                {{ option.attach_name }}
                              </b-form-checkbox>
                          </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>  
                    <b-col lg="12" class="px-0 cb-stra" v-else>
                      <b-form-group id="" label="Strategy" label-cols-lg="2" v-slot="{ addStraChecked }">
                          <div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 430px; padding: 6px 10px ">No Data ! You can only select after a Strategy is created.</div> 
                      </b-form-group>
                    </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
      </b-card>
	</section>
</template>
<script>

	import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import globalAdmin from '../../model/globalAdmin';

	export default {
      mixins: [globalAdmin],
	  	components: {
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	flatPickr
	  	},
	  	data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            goal_detail_tab: [],
            permission: [],
            status_goal_edit: {
              title: null,
              value: null
            },
            isEditing: true,
		        isDisabled: true,
            disableField: 'disabled-field',
            selectStatusGoal: [
              {title: 'Active', value: 0},  
              {title: 'Inactive', value: 1},          
              {title: 'Completed', value: 2},
            ],
            selectedStraDetail: [],
            optionsStraDetail: [],
            countStraDetailList: null,
        }	
		  },	
      watch: {
          "$route": function(val) {
              if(val){
                if(val.name == 'itees-strategic-edit-goal'){
                    this.isDisabled = false
                }
                else{
                  this.isDisabled = true
                }
              }
          },
      },
  		created(){
        this.permission = this.checkPermission(this.userData,"Strategic Plan");
        this.detailGoal()
        this.getStraListDetail()
        if(this.$route && this.$route.name == 'itees-strategic-edit-goal'){
          this.isDisabled = false
        }
        else{
          this.isDisabled = true
        }
  		},
  		methods: {
        toEdit(){
            this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/goal/edit/${this.$route.params.goal_id}`, query: {tab: 1} })
        },
        length1000(e){
            return String(e).substring(0,1000)
        },
        length100(e){
            return String(e).substring(0,100)
        },
        isdisabledCb(option) {
            return option.goal && option.attach_stratery_id != this.$route.params.goal_id
        },
  			detailGoal(){
            this.$store
            .dispatch('project/detailGoalStra', {
              id: this.$route.params.goal_id  
            })
            .then(response => {
              this.goal_detail_tab = response.data.data
              // console.log('goal detail list: ', response.data.data)

              if(this.goal_detail_tab.attach_status == 0) {
                this.status_goal_edit.title = 'Active'
                this.status_goal_edit.value = 0         
              }
              if(this.goal_detail_tab.attach_status == 1) {           
                this.status_goal_edit.title = 'Inactive'      
                this.status_goal_edit.value = 1     
              }
              if(this.goal_detail_tab.attach_status == 2) {           
                this.status_goal_edit.title = 'Completed'     
                this.status_goal_edit.value = 2     
              }
              // console.log(response.data.data)
            })
            .catch((error) => {
              console.log(error)
            })
        },
        getStraListDetail(){
            this.$store
            .dispatch('project/fetchStrategicPlan_GoalsStra', {
              filter: 
              {
                "attachable_id": this.$route.params.id,
                "list_search": [
                  {
                    "field": "attach_status",
                    "keyword": "0"
                  },{
                    "field": "attachable_type",
                    "keyword": "stratery"
                  },
                ]
              },
            })
            .then(response => {   
                this.optionsStraDetail = response.data.data
                this.countStraDetailList = this.optionsStraDetail.length

                this.selectedStraDetail = this.optionsStraDetail.filter(
                  item => item.attach_stratery_id == this.$route.params.goal_id
                ).map(item => item.id) // map ra field id
            })
            .catch((error) => {
              console.log(error)
            })
        },
        editAction(){
				  this.isDisabled = false
		      this.isEditing = false
		      this.disableField = ''
			  },
        cancelEditGoal(){
          this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1} })
        },
        saveEditGoal(){
            this.$refs.editGoalform_rel.validate().then(success => {
            if (success){
                let data = {
                    attach_name: this.goal_detail_tab.attach_name,
                    attach_status: this.status_goal_edit.value,
                    description: this.goal_detail_tab.description,
                    attach_outcome: this.goal_detail_tab.attach_outcome,
                    attach_stratery: this.selectedStraDetail,
                }
                this.$store
                .dispatch('project/editGoalStra', 
                {
                    id: this.$route.params.goal_id,
                    data: data
                })
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.goal_detail_tab.attach_name + ' Updated!',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center'
                      }
                    )
                    this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1} })
                })
                .catch((error) => {
                  console.log(error)
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.message,
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-center'
                    }
                  )
                })                  
            }
          })
        },
  		},
	};	
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
  .bold label{
		font-size: 16px !important;
	}
  .select-status svg {
    width: 25px;
    height: 20px;
  }
</style>