<template>
  <section>
    <b-card class="px-table-0 mt-1">
      <validation-observer ref="Goalform_rel" #default="{ invalid }">
        <b-form @submit.prevent class="profile-frm pb-0 pt-0" style="border-radius: 5px">
          <b-row class="align-center py-2">
            <b-col lg-12>
              <div class="text-right">

                <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelGoal"
                  style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

                <template v-if="(userData && permission.edit)">

                  <b-button type="button" variant="primary" class="btn-df size-18 black"
                    style="background-color: #8471FF !important; border-color: #8471FF !important" @click="saveGoals">
                    Save
                  </b-button>

                </template>
                <template v-else>
                  <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                    Save
                  </b-button>
                </template>
              </div>
            </b-col>
          </b-row>
          <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
          <div class="frm-basic size-16 black-child d-block">
            <b-row style="width: 100%; margin: 0;">

              <b-col lg="12" class="px-0">
                <b-form-group label="Goal Name" label-cols-lg="2" class="star-required">
                  <validation-provider #default="{ errors }" name="Goal Name" vid="goal-name" rules="required">
                    <b-form-input v-model="addGoal_fields.name" placeholder="Name" :formatter="length100"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="px-0">
                <b-form-group label="Status" label-cols-lg="4" class="star-required">
                  <validation-provider #default="{ errors }" name="Status" vid="goal-status" rules="required">
                    <v-select v-model="addGoal_fields.status" label="title" :options="selectStatusGoal"
                      placeholder="Select Status" class="select-status" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="px-0">
                <b-form-group label="Last Update" label-cols-lg="4" class="star-required">
                  <div class="calendarIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                      <path fill="WindowText"
                        d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
                      <path fill="none" d="M0 0h24v24H0z" />
                    </svg>
                  </div>
                  <b-form-input class="form-control fieldCalendar" placeholder="dd/mm/yy" readonly />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group label="Description" label-cols-lg="2" class="star-required">
                  <validation-provider #default="{ errors }" name="Description" vid="description" rules="required">
                    <b-form-textarea v-model="addGoal_fields.description" placeholder="Description" rows="4"
                      :formatter="length1000" style="padding-top: 12px !important"></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <div style="border-top: 1px solid #B1B1B1;" class="pb-2"></div>
                <b-form-group label="Outcome" label-cols-lg="2" class="star-required">
                  <validation-provider #default="{ errors }" name="Outcome" vid="goal-outcome" rules="required">
                    <b-form-textarea v-model="addGoal_fields.outcome" placeholder="Outcome" rows="4"
                      :formatter="length1000" style="padding-top: 12px !important"></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0 cb-stra-group" v-if="countStraEmptyList > 0">
                <b-form-group label="Strategy" label-cols-lg="2" v-slot="{ addStraChecked }">
                  <b-form-checkbox-group v-model="selectedStraEmpty" :options="optionsStraEmpty" value-field="id"
                    text-field="attach_name" disabled-field="goal" class='bold option' />
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0 cb-stra" v-else>
                <b-form-group label="Strategy" label-cols-lg="2">
                  <div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 430px; padding: 6px 10px ">No Data ! You
                    can only select after a Strategy is created.</div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </section>
</template>
<script>

import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../../model/globalAdmin'
export default {
  mixins: [globalAdmin],
  components: {
    Datepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      addGoal_fields: {
        name: null,
        status: null,
        last_update: null,
        description: null,
        outcome: null,
        strategy: null
      },
      permission: [],
      selectStatusGoal: [
        { title: 'Active', value: 0 },
        { title: 'Inactive', value: 1 },
        { title: 'Completed', value: 2 },
      ],
      selectedStraEmpty: [],
      optionsStraEmpty: [],
      countStraEmptyList: null,
      sort: {
        "field": "id",
        "type": "asc"
      },
      tam: [],
      disabled_cb: false
    }
  },
  created() {
    this.permission = this.checkPermission(this.userData,"Strategic Plan")
    this.getStraList()
  },
  methods: {
    length1000(e) {
      return String(e).substring(0, 1000)
    },
    length100(e) {
      return String(e).substring(0, 100)
    },
    getStraList() {
      this.$store
        .dispatch('project/fetchStrategicPlan_GoalsStra', {
          filter:
          {
            "attachable_id": this.$route.params.id,
            "list_search": [
              {
                "field": "attach_status",
                "keyword": "0"
              }, {
                "field": "attachable_type",
                "keyword": "stratery"
              }
            ]
          },
        })
        .then(response => {
          this.optionsStraEmpty = response.data.data
          this.countStraEmptyList = this.optionsStraEmpty.length
          // console.log('this.optionsStra: ', this.optionsStraEmpty)              
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // add new goal
    saveGoals() {
      this.$refs.Goalform_rel.validate().then(success => {
        if (success) {
          let data = {
            attachable_type: 'goals',
            attachable_id: this.$route.params.id,
            attach_name: this.addGoal_fields.name,
            attach_status: this.addGoal_fields.status.value,
            description: this.addGoal_fields.description,
            attach_outcome: this.addGoal_fields.outcome,
            attach_stratery: this.selectedStraEmpty ? this.selectedStraEmpty : null,
          }
          this.$store
            .dispatch('project/createGoal', data)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.addGoal_fields.name + ' Added!',
                  variant: 'success',
                },
              },
                {
                  position: 'top-center'
                }
              )

              this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: { tab: 1 } })
            })
            .catch((error) => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  variant: 'danger',
                },
              },
                {
                  position: 'top-center'
                }
              )
            })

        }
        else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'One or more fields have an error. Please check and try again.',
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            }
          )
        }
      })
    },
    cancelGoal() {
      this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: { tab: 1 } })
    },
  },
};

</script>
<!--  -->

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.Vue-Toastification__container.top-center {
  top: 20px;
}

.bold label {
  font-size: 16px !important;
}

.select-status svg {
  width: 25px;
  height: 20px;
}
</style>