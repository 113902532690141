<template>
	<section>
	    
	    	<template v-if="this.$route.name == 'itees-strategic-detail-addprogramme'">
	    		<b-card class="px-table-0 mb-0">
	    			<addProgramme />
	    		</b-card>
	    	</template>
	    	<template v-else-if="this.$route.name == 'itees-strategic-detail-editprogramme'">
	    		<b-card class="px-table-0 mb-0" style="background-color: transparent; box-shadow: none ">
	    			<editProgramme />
	    		</b-card>
	    	</template>
			<template v-else-if="this.$route.name == 'itees-strategic-detail-programme'">
	    		<b-card class="px-table-0 mb-0" style="background-color: transparent; box-shadow: none ">
	    			<detailProgrammes />
	    		</b-card>
	    	</template>
	    	<template v-else>
	    		<b-card class="px-table-0 mb-0 w-20-percent-cl-2 w-25-percent-cl-3 w-15-percent-cl-4">
	                <b-row class="align-center py-2">
			            <b-col lg-12>
			            	<div class="text-right mr-2">
			            		<template v-if="this.$route.name == 'itees-strategic-edit'">
			            			<template v-if="(userData && permission.edit)">

					                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="addProgramme">
					                        Add Programme
					                    </b-button> 

					                </template>
					                <template v-else>
					                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
					                        Add Programme
					                    </b-button>
					                </template>
				                </template>
				                <template v-else>

				                	<template v-if="(userData && permission.edit)">

					                	<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEditTab($route.params.id, 1, 2)">
					                        Add Programme
					                    </b-button> 
					                </template>
				                    <template v-else>
					                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
					                        Add Programme
					                    </b-button>  
					                </template>      
				                </template>       
			                </div>
			            </b-col>
			        </b-row>	
				    <b-table small :fields="fields" :items="items" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="sortClicked" @row-clicked="detailProgramme">
				    	<template #head(id)="data">
				    		<span>{{ data.label }}</span>
				    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sortPro.field && sortPro.type == 'desc' }]" style="position: relative; left: 30px">
					        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
					        </span>
				    	</template>
				    	<template #head(name)="data">
				    		<span class="d-block text-left">{{ data.label }}</span>
				    	</template>		
				    	<template #head()="data">
				    		<span class="d-block text-center">{{ data.label }}</span>
				    	</template>						    	   	
				    	<template #cell(id)="data">
							<span>{{ data.item.id }}</span>
						</template>		
						<template #cell(name)="data">
							<span>{{ data.item.name }}</span>
						</template>		
						<template #cell(goal)="data">
							<span class="maxTwoRow text-center">{{ data.item.goal }}</span>
						</template>	
						<template #cell(stratery)="data">
							<span class="maxTwoRow text-center">{{ data.item.stratery }}</span>
						</template>	
						<template #cell(action)="data">
							<div class="d-block text-center">
								<template v-if="(userData && permission.edit)">

									<span class="px-05 cursor-pointer" @click="programme_detail_edit(data.item.id)">
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
										</svg>
									</span>
									<template v-if="userData && permission.edit">
										<span class="px-05 cursor-pointer" @click.stop="showDelete(data.item.id, data.item.name)">
											<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
											</svg>
										</span>
									</template>
									<template v-else>
										<span class="px-05">
											<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
											</svg>
										</span>
									</template>
								</template>
								<template v-else>
									<span class="px-05">
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
										</svg>
									</span>
									<span class="px-05">
										<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
										</svg>
									</span>
								</template>
							</div>										
						</template>	
						<template #cell(status)="data">
							<span class="d-block text-center" v-if="data.item.status == 0">Yet to Launch</span>
							<span class="d-block text-center" v-else-if="data.item.status == 1">Launched</span>
							<span class="d-block text-center" v-else-if="data.item.status == 2">Developing</span>
						</template>		
						<template #cell()="data">
							<span class="d-block text-center">{{ data.value }}</span>
						</template>				
						<template #empty="scope">
					      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
					    </template>
					</b-table>
				</b-card>
				<div v-if="this.total > 0" class="paging-cus relative mt-2">
				<b-pagination
					v-model="currentPage"
					:total-rows="total"
					:per-page="perPage"
					aria-controls="custom-table"
					first-number
					last-number
					class="just-center"
				></b-pagination>
				<div class="limit-per">
					<select v-model="selected" class="cursor-pointer">
						<option v-for="limit in limitpage">{{ limit }}</option>
					</select>			  
				</div>
			</div>
			</template>
	</section>
</template>
<script>

	import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import addProgramme from '../programme/Add'
    import detailProgrammes from '../programme/Detail'
    import editProgramme from '../programme/Edit'
	import globalAdmin from '../../model/globalAdmin';

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Datepicker,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,

          	addProgramme,
          	editProgramme,
			detailProgrammes,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	fields: [
                	{ key: 'id', label: 'PID.' },
			        { key: 'name', label: 'Programme Name' },			        
			        { key: 'stratery', label: 'Strategy' },			        
			        { key: 'goal', label: 'Goal' },
			        { key: 'status', label: 'Status' },
			        { key: 'action', label: 'Actions' },			
			    ],   
				permission: [],
				perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
			    items: [
			    ],
			    sortPro: {
			    	"field": "id",
		      		"type": "asc"
			    },
            }	
		},	
		watch: {
			"$route": function(val) {
				if(val) {
					this.fetchItem()
				}
			},
			currentPage(val){
				this.fetchItem()
			},
			selected(val){
			if(val) {
				this.perPage = val
				this.fetchItem()
			}
        	}
		},
		created(){
			this.permission = this.checkPermission(this.userData,"Strategic Plan");
			this.fetchItem()
		},
		methods: {
			toEditTab(id, tabIndex, child){
				this.$router.push( { path: `/admin/itees-strategic-plan/edit/${id}`, query: {tab: tabIndex, child: child}})
			},
			fetchItem(){
				this.$store
				.dispatch('project/fetchStrategicPlan_ProgrammeList', {
					filter: 
					{ "strategic_plan": this.$route.params.id },
					sort: this.sortPro,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {	
					this.items = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			sortClicked(key){
				if(this.sortPro.field == key){
					if(this.sortPro.type == 'asc') this.sortPro.type = 'desc'
					else this.sortPro.type = 'asc'
				}
				else{					
					this.sortPro.field = key
					this.sortPro.type = 'desc'
				}
				this.fetchItem(); 
			},		
			showDelete(id, name){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteItem(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteItem(id, title){
				this.$store
				.dispatch('project/deleteProgrammeItem', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItem()	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},		
			detailProgramme(item) {
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/programme/${item.id}`, query: {tab: 1, child: 2} });
			},
			programme_detail_edit(id){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/programme/edit/${id}`, query: {tab: 1, child: 2} });
			},	
			addProgramme(){
		        this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/add-programme`, query: {tab: 1, child: 2} })
		    },
		},
	};	

</script>
<!--  -->

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
</style>