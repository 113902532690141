<template>
	<section>
    <validation-observer ref="form_rel" #default="{invalid}" >
        <b-form @submit.prevent class="profile-frm pb-0 pt-0" style="border-radius: 5px">
            <b-row class="align-center py-2">
                <b-col lg-12>
                  <div class="text-right">

                        <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelProgramme" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

                        <template v-if="(userData && permission.edit)">

                            <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="saveProgramme">
                                Save
                            </b-button>

                        </template>
                        <template v-else>
                            <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                              Save
                          </b-button> 
                        </template>
                    </div>
                </b-col>
            </b-row>  
            <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
            <div class="frm-basic size-16 black-child d-block">
              <b-row style="width: 100%; margin: 0;">  
                <b-col lg="12" class="px-0">
                    <b-form-group label="Programme Name" label-cols-lg="2" class="star-required">     
                      <validation-provider
                        #default="{ errors }"
                        name="Programme Name"
                        vid="name"
                        rules="required"                      
                      >                        
                          <b-form-input
                            v-model="addProgramme_fields.name"
                              placeholder="Name"
                              :formatter="length100"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Status" label-cols-lg="4" class="star-required">
                    <validation-provider
                        #default="{ errors }"
                        name="Status"
                        vid="status"
                        rules="required"                      
                      >
                        <v-select
                            v-model="addProgramme_fields.status"
                            label="title"
                            :options="selectStatusProgramme"
                            placeholder="Select Status"
                          />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Last Update" label-cols-lg="4" class="star-required">
                    <div class="calendarIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                          </div>
                      <flat-pickr
                          v-model="addProgramme_fields.last_update"
                          class="form-control fieldCalendar" 
                          :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'd/m/Y' }"
                          placeholder="dd/mm/yy"
                          disabled
                      />
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Strategy" label-cols-lg="4">
                      <v-select
                          v-model="addProgramme_fields.strategy"
                          label="attach_name"
                          :options="selectStrategy"
                          placeholder="Select Strategy"
                        />
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Goal" label-cols-lg="4">              
                      <b-form-input
                        v-model="addProgramme_fields.goal"
                          placeholder=""
                          readonly
                      ></b-form-input>
                  </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <b-form-group label="Programme Description" label-cols-lg="2">     
                      <validation-provider
                        #default="{ errors }"
                        name="Programme Description"
                        vid="description"                   
                      >                        
                          <b-form-textarea
                            v-model="addProgramme_fields.description"
                              placeholder="Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                      </validation-provider>
                    </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <div style="border-top: 1px solid #B1B1B1;" class="pb-2"></div>
                </b-col>
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Assign Staff" label-cols-lg="4" class="star-required">
                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important; max-width: 100%; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) " @click="addStaff">
                        Add Staff
                    </b-button>      
                  </b-form-group>
                </b-col> 
                <b-col lg="6" class="px-0">             
                  <b-form-group label="Launched Date" label-cols-lg="4" class="star-required">
                    <div class="calendarIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                    </div>
                          <flat-pickr
                              v-model="addProgramme_fields.launched_date"
                              placeholder="Select date"
                              class="form-control fieldCalendar"           
                              :config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d'}"
                              required
                          />
                  </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <b-form-group label="Staff Assigned" label-cols-lg="2" class="star-required">     
                        <div class="staff-selected disabled-field">
                          <div v-if="selectedStaff">
                              {{ selectedStaff.map(item=>item.full_name).join(", ") }}
                          </div>                                
                        </div>

                    </b-form-group>
                </b-col>  
                <b-col lg="12" class="px-0">
                    <div style="border-top: 1px solid #B1B1B1;" class="pb-2"></div>
                </b-col>
                <b-col lg="6" class="px-0">
                    <b-form-group label="Key Performance Indicators (KPI)" label-cols-lg="4">     
                      <validation-provider
                        #default="{ errors }"
                        name="Key Performance Indicators (KPI)"
                        vid="key_performance"                     
                      >                        
                          <b-form-textarea
                            v-model="addProgramme_fields.key_performance"
                              placeholder="KPI Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                      </validation-provider>
                    </b-form-group>
                </b-col>  
                <b-col lg="6" class="px-0">
                    <b-form-group label="KPI Achievements" label-cols-lg="4">     
                      <validation-provider
                        #default="{ errors }"
                        name="KPI Achievements"
                        vid="achievements"                      
                      >                        
                          <b-form-textarea
                            v-model="addProgramme_fields.kpi_archive"
                              placeholder="KPI Achievement Description"
                              rows= "4"
                              :formatter="length1000"
                              style="padding-top: 12px !important"
                          ></b-form-textarea>
                      </validation-provider>
                    </b-form-group>
                </b-col>  
              </b-row>
            </div>
            <b-modal
              ref="add_staff"
              title="Add Staff" 
              no-close-on-backdrop  
              hide-header-close 
              id="addStaff_modal">
                <b-row style="padding-right: 30px">                
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Select Staff" label-cols-xl="3"
                          label-cols-md="3" label-class="text-lg-right bold">
                            <validation-provider
                              #default="{ errors }"
                              name="Select Staff"
                              vid="time_schedule"
                              rules="required"               
                          >   
                              <v-select
                                v-model="selectedStaff"
                                :close-on-select="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="full_name"
                                :options="optionsStaff"
                                placeholder="Select Staff"
                                class="timebox form-control px-0 border-none h-auto"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </b-col>                  
                    <b-col lg="12" class="px-0">
                        <b-form-group label="Staff Selected" label-cols-xl="3"
                          label-cols-md="3" label-class="text-lg-right bold">

                              <div class="staff-selected disabled-field">
                                <div v-if="selectedStaff">
                                    {{ selectedStaff.map(item=>item.full_name).join(", ") }}
                                </div>                                
                              </div>
                        </b-form-group>

                    </b-col>
                </b-row>   
                <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="static" class="btn-default" @click="cancelStaff">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" class="btn-primary" @click="saveStaff">
                        Save
                    </b-button>
                </template> 
          </b-modal>
        </b-form>
    </validation-observer>
	</section>
</template>

<script>

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import flatPickr from 'vue-flatpickr-component'
    import Multiselect from 'vue-multiselect'
    import globalAdmin from '../../model/globalAdmin';
    const moment = require('moment');

	export default {
      mixins: [globalAdmin],
	  	components: {
        	vSelect,
        	ValidationProvider,
        	ValidationObserver,
          Multiselect,
          flatPickr
	  	},
	  	data() {
    		  return {
              userData: JSON.parse(localStorage.getItem('userData')),
    			    addProgramme_fields: {
                name: null,
                last_update: null,
                status: null,
                strategy: null,
                goal: null,
                description: null, 
                launched_date: null,
                key_performance: null,
                kpi_archive: null

              },
              selectStrategy: [],
              selectStatusProgramme: [
                {title: 'Yet to Launch', value: 0}, 
                {title: 'Launched', value: 1},          
                {title: 'Developing', value: 2},
              ],   
              permission: [],
              dir: 'ltr',
              selectedStaff: [],
              optionsStaff: [],
              staff_selected_array: [],
          }	
		},	
    watch: {            
        'addProgramme_fields.strategy'(val) {
           if(val){
              if(val.goal && val.goal != null){
                this.addProgramme_fields.goal = val.goal.attach_name
              }
              else this.addProgramme_fields.goal = null
           }
        }, 
    },
    computed: {
        mintoday(){            
            var d = new Date()
            d.setDate(d.getDate())
            var now = moment(d).format('YYYY-MM-DD')
            return now
        },
    },
		created(){
        this.permission = this.checkPermission(this.userData,"Strategic Plan");
        this.listStrategy()
        this.listStaff()
		},
		methods: {			     
      length1000(e){
          return String(e).substring(0,1000)
      },
      length100(e){
          return String(e).substring(0,100)
      }, 
      listStrategy(){
          this.$store
          .dispatch('project/fetchStrategicPlan_GoalsStra', { 
              filter: {
                "attachable_id": this.$route.params.id,
                "list_search": [
                 {
                    "field": "attachable_type",
                    "keyword": "stratery"
                  }
                ]
              }
          })
          .then(response => {
              this.selectStrategy = response.data.data
              // console.log(this.selectStrategy)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      listStaff(){
          this.$store
          .dispatch('auth/fetch_staff_log_list', {
              filter: {
                "group":"1",
                "role":"4"
              }
          })
          .then(response => {
              this.optionsStaff = response.data.data.data
              // console.log('options staff: ', response.data.data.data)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      saveProgramme(){
          this.$refs.form_rel.validate().then(success => {
            if (success)
            {
              if(this.staff_selected_array && this.staff_selected_array.length > 0){
                let data = {
                    name: this.addProgramme_fields.name,
                    status: this.addProgramme_fields.status.value,  
                    stratery_id: this.addProgramme_fields.strategy ? this.addProgramme_fields.strategy.id : null,
                    description: this.addProgramme_fields.description || null,
                    kpi_description: this.addProgramme_fields.key_performance || null,
                    kpi_achievement_description: this.addProgramme_fields.kpi_archive || null,
                    launched_date: this.addProgramme_fields.launched_date,
                    staff_assign: this.staff_selected_array,
                    strategic_plan_id: this.$route.params.id
                }
                // console.log(data)
                this.$store
                .dispatch('project/createProgramme', data)
                .then(response => {
                    this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.addProgramme_fields.name + ' Added!',
                            variant: 'success',
                          },
                      },
                      {
                          position: 'top-center'
                      }
                    )
                    this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 2} })
                })
                .catch((error) => {
                  console.log(error)
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.message,
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-center'
                    }
                  )
                })  
              }
              else{
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Please click "Add Staff" before Save',
                        variant: 'success',
                      },
                  },
                  {
                      position: 'top-center'
                  }
                )
              }
            }
            else {
						  this.$toast({
	             component: ToastificationContent,
	              props: {
	              title: 'One or more fields have an error. Please check and try again.',
	              variant: 'danger',
	              },
	             },
	            {
	                position: 'top-center'
	             }
	            )
					}
          })
      },
      cancelProgramme(){
          this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 2} })
          this.selectedStaff = null
      },
      addStaff(){
          this.$refs['add_staff'].show()
      },
      cancelStaff(){
          this.$refs['add_staff'].hide()
          this.selectedStaff = null
      },
      saveStaff(){
          this.staff_selected_array = this.selectedStaff.map(item => item.id )
          this.$refs['add_staff'].hide()
          // console.log('result: ', this.staff_selected_array.length)
      },
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
  #addStaff_modal .modal-dialog {
      max-width: 650px;
  }
</style>