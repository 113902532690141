<template>
	<section>
		<template v-if="this.$route.name == 'itees-strategic-detail-addstrategy'">
 			<addStrategies />
 		</template>	
 		<template v-else-if="this.$route.name == 'itees-strategic-detail-editstra'">
 			<editStrategies />
 		</template>	
		 <template v-else-if="this.$route.name == 'itees-strategic-detail-stra'">
 			<detailStrategies />
 		</template>	
 		<template v-else>
			<b-card class="px-table-0 mt-1">
			    <b-card class="px-table-0 mb-0 w-20-percent-cl-2 w-25-percent-cl-3">
	                <b-row class="align-center py-2">
			            <b-col lg="6" style="padding-left: 33px ">
			                <h2 class="mb-0"></h2>
			            </b-col>
			            <b-col lg-6>
			            	<div class="text-right mr-2">
			            		<template v-if="this.$route.name == 'itees-strategic-edit'">

			            			<template v-if="(userData && permission.edit)">

					                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="addStrategies">
					                        Add Strategy
					                    </b-button>
					                </template>
					                <template v-else>
					                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
					                        Add Strategy
					                    </b-button>
					                </template>

				                </template>     
				                <template v-else>
				                	<template v-if="(userData && permission.edit)">

					                	<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEditTab($route.params.id, 1, 1)">
					                        Add Strategy
					                    </b-button> 
					                </template>
					                <template v-else>
					                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
					                        Add Strategy
					                    </b-button>  
					                </template>
				                </template> 
			                </div>
			            </b-col>
			        </b-row>	
				    <b-table small :fields="fields_strategy" :items="strategy" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="sortClickedStra" @row-clicked="detailStrategy">
				    	<template #head(id)="data">
				    		<span>{{ data.label }}</span>
				    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sortStra.field && sortStra.type == 'desc' }]" style="position: relative; left: 30px">
					        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
					        </span>
				    	</template>
				    	<template #head()="data">
				    		<span class="d-block text-center">{{ data.label }}</span>
				    	</template>	
				    	<template #head(attach_name)="data">
				    		<span class="d-block text-left">{{ data.label }}</span>
				    	</template>						    	
				    	<template #cell(id)="data">
							<span>{{ data.item.id }}</span>
						</template>
						<template #cell(attach_name)="data">
							<span class="maxTwoRow">{{ data.item.attach_name }}</span>
						</template>			
						<template #cell(action)="data">
							<div class="d-block text-center">
								<template v-if="(userData && permission.edit)">

									<span class="px-05 cursor-pointer" @click.stop="showCloneAction(data.item.id, data.item.attach_name)">
										<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM13.59 4.59L18.42 9.42C18.79 9.79 19 10.3 19 10.83V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H12.17C12.7 4 13.21 4.21 13.59 4.59ZM13 11H17.5L12 5.5V10C12 10.55 12.45 11 13 11Z" fill="#0F1EAE"/>
										</svg>
									</span>
									<span class="px-05 cursor-pointer" @click.stop="strategy_edit(data.item.id)">
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
										</svg>
									</span>
									<template v-if="userData && permission.edit">
										<span class="px-05 cursor-pointer" @click.stop="showDelete(data.item.id, data.item.attach_name)">
											<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
											</svg>
										</span>
									</template>
									<template v-else>
										<span class="px-05">
											<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
											</svg>
										</span>
									</template>
								</template>
								<template v-else>
									<span class="px-05">
										<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM13.59 4.59L18.42 9.42C18.79 9.79 19 10.3 19 10.83V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H12.17C12.7 4 13.21 4.21 13.59 4.59ZM13 11H17.5L12 5.5V10C12 10.55 12.45 11 13 11Z" fill="#b1b1b1"/>
										</svg>
									</span>
									<span class="px-05">
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
										</svg>
									</span>
									<span class="px-05">
										<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
										</svg>
									</span>
								</template>
							</div>										
						</template>	
						<template #cell(programs)="data">
							<div class="d-block text-center" v-if="data.item.programme_count > 0">
								<div class="text-center d-block cursor-pointer decoration-hover" style="color: #8471FF" @click.stop="showStraList_programme(data.item.id)">
									List
								</div>
							</div>	
						</template>	
						<template #cell(attach_status)="data">
							<span class="d-block text-center" v-if="data.item.attach_status == 0">Active</span>
							<span class="d-block text-center" v-else-if="data.item.attach_status == 1">Inactive</span>
							<span class="d-block text-center" v-else-if="data.item.attach_status == 2">Completed</span>
						</template>		
						<template #cell(attach_stratery_name)="data">
							<span class="maxTwoRow text-center" v-if="!data.item.goal">{{ data.item.goal }}</span>
							<span class="maxTwoRow text-center" v-else>{{ data.item.goal.attach_name }}</span>
						</template>
						<template #cell()="data">
							<span class="d-block text-center">{{ data.value }}</span>
						</template>				
						<template #empty="scope">
					      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
					    </template>
					</b-table>
				</b-card>
	            <b-modal id="modalList" ref="strategies_programme_list" title="Programmes list" hide-header-close ok-title="Save" centered>
					<div class="list-content">
						<b-col lg="12" class="px-0 cb-stra-group" v-if="count_stra_programme_list > 0 ">
							<b-form-group class="title-content" label="Programmes" label-cols-lg="2" v-slot="{ editStraChecked }">
									<b-form-checkbox-group
										v-model="selectedPro"
										:options="stra_programme_list"
										value-field="id"
										text-field="name"
										class='bold'
									/>
							</b-form-group>                
						</b-col>
						<b-col lg="12" class="px-0 cb-stra" v-else>
							<b-form-group label="Strategy" label-cols-lg="2" >
								<div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 430px; padding: 6px 10px ">No Data ! You can only select after a Strategy is created.</div> 
							</b-form-group>
						</b-col>
					</div>     
	                <template #modal-footer="{ cancel }">
	                    <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
	                      	Close
	                    </b-button>                    
	                </template>             
	            </b-modal>
			</b-card>
			<div v-if="this.total > 0" class="paging-cus relative">
				<b-pagination
					v-model="currentPage"
					:total-rows="total"
					:per-page="perPage"
					aria-controls="custom-table"
					first-number
					last-number
					class="just-center"
				></b-pagination>
				<div class="limit-per">
					<select v-model="selected" class="cursor-pointer">
						<option v-for="limit in limitpage">{{ limit }}</option>
					</select>			  
				</div>
			</div>
		</template>
	</section>
</template>
<script>

	import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import addStrategies from '../strategy/Add'
    import editStrategies from '../strategy/Edit'
    import detailStrategies from '../strategy/Detail'


	export default {
	  	components: {
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,

          	addStrategies,
          	editStrategies,
			detailStrategies,
	  	},
	  	data() {
		    return {
			    userData: JSON.parse(localStorage.getItem('userData')),
		    	fields_strategy: [
                	{ key: 'id', label: 'STID.' },
			        { key: 'attach_name', label: 'Strategy Name' },			        
			        { key: 'attach_stratery_name', label: 'Goal' },
			        { key: 'attach_status', label: 'Status' },
			        { key: 'programs', label: 'Programmes' },
			        { key: 'action', label: 'Actions' },			
			    ],   
				permission:[],
				perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
			    strategy: [
			    ],
			    sortStra: {
			    	"field": "id",
		      		"type": "asc"
			    },
			    fields_stra_programme_list: [
                	{ key: 'no', label: 'No.' },
			        { key: 'name', label: 'Activity Listing' },			        
			        { key: 'action', label: 'Actions' },			
			    ],   
			    stra_programme_list: [],
				count_stra_programme_list: 0,
				selectedPro: [],
            }	
		},	
		watch: {
			"$route": function(val) {
				if(val) {
					this.fetchItemStra()
				}
			},
			currentPage(val){
				this.fetchItemStra()
			},
			selected(val){
			if(val) {
				this.perPage = val
				this.fetchItemStra()
			}
        	}
		},
		created(){
			this.checkPermission("Strategic Plan")
			this.fetchItemStra()
		},
		methods: {
			toEditTab(id, tabIndex, child){
				this.$router.push( { path: `/admin/itees-strategic-plan/edit/${id}`, query: {tab: tabIndex, child: child}})
			},
			checkPermission(namePage){
				var action = [];
				this.userData?.permission.map(function (value) {
					if(value.module === namePage){
						action = value.actions
					}
				})
				this.permission = action 
			},
			fetchItemStra(){
				this.$store
				.dispatch('project/fetchStrategicPlan_GoalsStra', {
					filter: {
					  "attachable_id": this.$route.params.id ,
					  "count":"program",
					  "list_search": [
					   {
					      "field": "attachable_type",
					      "keyword": "stratery"
					    }
					  ]
					},
					sort: this.sortStra,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {		
					this.strategy = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			sortClickedStra(key){
				if(this.sortStra.field == key){
					if(this.sortStra.type == 'asc') this.sortStra.type = 'desc'
					else this.sortStra.type = 'asc'
				}
				else{					
					this.sortStra.field = key
					this.sortStra.type = 'desc'
				}
				this.fetchItemStra(); 
			},
			showStraList_programme(id_item){
				this.count_stra_programme_list = 0
				this.fetchItemGoals_item_programme(id_item)
				this.$refs['strategies_programme_list'].show()
			},
			fetchItemGoals_item_programme(id_stra){
				this.$store
				.dispatch('project/fetchStrategicPlan_ProgrammeList', {
					filter: 
					{  "strategic_plan": this.$route.params.id,
						"list_search": 
						[
							{ "field": "strategy_id","keyword": id_stra }
						]
					}

				})
				.then(response => {	
					this.stra_programme_list = response.data.data
					this.count_stra_programme_list = this.stra_programme_list.length
					this.selectedPro = this.stra_programme_list.filter(
						item => item.id != 0
					).map(item => item.id)
				})
				.catch((error) => {
					console.log(error)
				})
			},		
			showDelete(id, name){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteItem(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteItem(id, title){
				this.$store
				.dispatch('project/deleteStrategicItem', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItemStra()	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
			},
			showCloneAction(id, name){
				this.$bvModal.msgBoxConfirm('Please confirm that you want to copy.', {
		          title: 'Please Confirm',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'YES',
		          cancelTitle: 'NO',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.copyAction(id, name)
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			copyAction(id, title){
				this.$store
				.dispatch('project/copyGloalStra', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Duplicated!',
	                          variant: 'success',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItemStra()	                			
				})
				.catch((error) => {
					console.log(error)
				})
			},
			addStrategies(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/add-strategy`, query: {tab: 1, child: 1} })
			},
			detailStrategy(item) {
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/strategy/${item.id}`, query: {tab: 1, child: 1} })
			},
			strategy_edit(id){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}/strategy/edit/${id}`, query: {tab: 1, child: 1} })
			},
		},
	};	

</script>
<!--  -->

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	#modalList .modal-dialog{
		max-width: 1084.38px !important;
	}
	.list-content{
		padding-left: 60px;
	}
	.list-content .title-content legend{
		font-size: 16px;
		font-weight: 700 !important;
	}
	.list-content .bold{
		pointer-events: none;
	}
</style>