<template>
	<section>
		<b-card class="px-table-0 mt-1">
			<validation-observer ref="Straform_rel" #default="{invalid}" >
				<b-form @submit.prevent class="profile-frm pb-0 pt-0" style="border-radius: 5px">
					<b-row class="align-center py-2">
			            <b-col lg-12>
			            	<div class="text-right">

			            		<b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAddStra" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

			            		<template v-if="(userData && permission.edit)">

				                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important; border-color: #8471FF !important" @click="saveAddStra">
				                        Save
				                    </b-button>     
				                </template> 
				                <template v-else>
		                              <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                                Save
		                            </b-button> 
		                        </template>
			                </div>
			            </b-col>
			        </b-row>	
			        <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
			        <div class="frm-basic size-16 black-child d-block">
			        	<b-row style="width: 100%; margin: 0;">  

			        		<b-col lg="12" class="px-0">
	                          	<b-form-group label="Strategy Name" label-cols-lg="2" class="star-required">     
	                          		<validation-provider
	                                  #default="{ errors }"
	                                  name="Strategy Name"
	                                  vid="strategy-name"
	                                  rules="required"                      
	                                >                        
	                                    <b-form-input
	                                    	v-model="addStra_fields.name"
	                                      	placeholder="Name"
	                                      	:formatter="length100"
	                                    ></b-form-input>
	                                    <small class="text-danger">{{ errors[0] }}</small>
	                                </validation-provider>
	                          	</b-form-group>
	                      	</b-col> 
	                      	<b-col lg="6" class="px-0">             
	                            <b-form-group label="Status" label-cols-lg="4" class="star-required">
	                            	<validation-provider
	                                  #default="{ errors }"
	                                  name="Status"
	                                  vid="goal-status"
	                                  rules="required"                      
	                                >
		                                <v-select
		                                	v-model="addStra_fields.status"
		                                    label="title"
		                                    :options="selectStatusStra"
		                                    placeholder="Select Status"
											class="select-status"
	                                    />
		                                <small class="text-danger">{{ errors[0] }}</small>
	                                </validation-provider>
	                            </b-form-group>
	                      	</b-col> 
	                      	<b-col lg="6" class="px-0">
	                            <b-form-group label="Last Update" label-cols-lg="4" class="star-required">
									<div class="calendarIcon">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
									</div>
	                                <b-form-input
	                                  	class="form-control fieldCalendar" 
	                                  	placeholder="dd/mm/yy"
	                                  	readonly
	                                />
	                            </b-form-group>
	                      	</b-col> 
	                      	<b-col lg="12" class="px-0">
	                          	<b-form-group label="Description" label-cols-lg="2" class="star-required">     
	                          		<validation-provider
	                                  #default="{ errors }"
	                                  name="Description"
	                                  vid="description"
	                                  rules="required"                      
	                                >                        
	                                    <b-form-textarea
	                                    	v-model="addStra_fields.description"
	                                      	placeholder="Description"
	                                      	rows= "4"
	                                      	:formatter="length1000"
											style="padding-top: 12px !important"
	                                    ></b-form-textarea>
	                                    <small class="text-danger">{{ errors[0] }}</small>
	                                </validation-provider>
	                          	</b-form-group>
	                      	</b-col> 
	                      	<b-col lg="12" class="px-0 cb-stra-group" v-if="countPro > 0 ">
	                      		  
		                      		<b-form-group label="Programmes" label-cols-lg="2" v-slot="{ addStraChecked }">	
									    <b-form-checkbox-group
			                                v-model="selectedPro"
			                                :options="optionsPro"
			                                value-field="id"
			                                text-field="name"
			                                disabled-field="stratery_id"
			                                class='bold'
			                            />
		                      		</b-form-group>
	                      		
	                      	</b-col>
	                      	<b-col lg="12" class="px-0 cb-stra" v-else>
	                      		<b-form-group id="" label="Programmes" label-cols-lg="2" >
		                          	<div style="color: #B1B1B1; border: 1px solid #B1B1B1; width: 440px; padding: 6px 10px ">No Data ! You can only select after programme is created.</div> 
	                      		</b-form-group>
	                      	</b-col>
			        	</b-row>
			        </div>
			    </b-form>
	    </validation-observer>
	</b-card>
	</section>
</template>
<script>

	import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../model/globalAdmin';

	export default {
		mixins: [globalAdmin],
		permission: [],
	  	components: {
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	flatPickr
	  	},	  	
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
			    addStra_fields: {
			    	name: null,
			    	status: null,
			    	last_update: null,
			    	description: null,
			    	programme: null
			    },
				permission:[],
			    selectStatusStra: [
		            {title: 'Active', value: 0},  
		            {title: 'Inactive', value: 1},          
		            {title: 'Completed', value: 2},
		        ],
			    selectedPro: [],
		        optionsPro: [],
		        countPro: null,
            }	
		},	
		created(){
			this.permission = this.checkPermission(this.userData,"Strategic Plan");
			this.getProgrammeList()
		},
		methods: {
			length1000(e){
			    return String(e).substring(0,1000)
			},
			length100(e){
			    return String(e).substring(0,100)
			},
			getProgrammeList(){
	          	this.$store
	            .dispatch('project/fetchStrategicPlan_ProgrammeList', {
	              	filter: { 
	              			"strategic_plan": this.$route.params.id, 
	              			"list_search": [{ "field": "status","keyword": "0"}] 
	              		}
	          	})
		        .then(response => {   
		            this.optionsPro = response.data.data
		            this.countPro = this.optionsPro.length
		            // console.log('optionsPro', response.data.data)
		        })
		        .catch((error) => {
		            console.log(error)
		        })
	        },

			cancelAddStra(){
				this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 1} })
			},
			saveAddStra(){
				this.$refs.Straform_rel.validate().then(success => {
	            if (success){
		            let data = {
		                attachable_type: 'stratery',
		                attachable_id: this.$route.params.id,
		                attach_name: this.addStra_fields.name,        
		                attach_status: this.addStra_fields.status.value,   
		                description: this.addStra_fields.description,
		                list_programme: this.selectedPro ? this.selectedPro : null
		            }
	              	this.$store
	              	.dispatch('project/createStra', data)
	              	.then(response => {
		                this.$toast(
		                	{
		                        component: ToastificationContent,
		                        props: {
		                          title: this.addStra_fields.name + ' Added!',
		                          variant: 'success',
		                        },
		                  	},
		                  	{
		                        position: 'top-center'
		                  	}
		                )
		                this.$router.push({ path: `/admin/itees-strategic-plan/edit/${this.$route.params.id}`, query: {tab: 1, child: 1} })
		                .catch((error) => {
		                  	console.log(error)
		                  	this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                       title: error.response.data.message,
			                       variant: 'danger',
			                    },
		                    },
		                    {
		                      position: 'top-center'
		                    }
		                  )
		                })  
	              	})
	            }
				else {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
					}
	          	})
			},
		},
	};	

</script>
<!--  -->

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	.bold label{
		font-size: 16px !important;
	}
	.select-status svg {
		width: 25px;
		height: 20px;
	}
</style>